export default {
  install: function (Vue) {
    console.log('Installing isGuest')

    Vue.prototype.$isGuest = function () {
      const isGuestMeta = document.head.querySelector('meta[name="is-guest"]')
      if (!isGuestMeta) {
        return true
      }
      return isGuestMeta.content !== '0'
    }
  }
}
