// We don't want to persist this by default
export const persist = false

const getDefaultState = () => {
  return {
    source: 'none',
    user: {},
  }
}

export const state = getDefaultState()

export const types = {
  SET_USER: 'SET_USER',
  RESET_AUTH_STATE: 'RESET_AUTH_STATE',
}

export const mutations = {
  [types.SET_USER](state, user) {
    state.user = user
  },
  [types.RESET_AUTH_STATE](state) {
    Object.assign(state, getDefaultState(false))
  },
}

export const actions = {
  /**
   * Reset the auth state, this is used on logout to
   * set the state back to it initial state.
   * @param commit
   */
  resetState({ commit }) {
    commit(types.RESET_AUTH_STATE)
  },

  setUser({ commit }, payload) {
    commit(types.SET_USER, payload)
    return true
  },

  // Not sure if this should be here or elsewhere in the AuthApi service...
  logout({ commit }) {
    commit(types.RESET_AUTH_STATE)
  },
}

export const getters = {
  check: state => {
    return !!state.user?.id || false
  },
  user: state => state.user.attributes || null,
  isCoach: state =>
    state.user.attributes && state.user.attributes.roles
      ? Boolean(state.user.attributes.roles.coach)
      : false,
  isCoachee: state =>
    state.user.attributes && state.user.attributes.roles
      ? Boolean(state.user.attributes.roles.coachee)
      : false,
  avatar: state => state.user.relationships?.avatar?.attributes.url || null,
}
