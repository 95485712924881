import moment from 'moment'
import { mean, round } from 'lodash'

moment.fn.daysSinceEpoch = function () {
  return Math.floor(this.unix() / (60 * 60 * 24))
}

/**
 * A helper method for generating a PHP Compatible Unix timestamp.
 * @returns {number}
 * @constructor
 */
export function PHPUnixTime () {
  return Math.floor(new Date().getTime() / 1000)
}

export function momentFromPHPUnixTime (unix) {
  return moment.unix(unix)
}

/**
 * Returns score as a range between 0 - 100 based upon
 * provided behaviours.
 *
 * @see https://codepen.io/Wol/pen/xMQVwP
 * @see https://codepen.io/Wol/pen/xMQVwP
 * @param {number[]} inputValues
 * @param {number} max_score
 */
export function calculateOverallScoreFromBehaviours (inputValues, max_score = 9) {
  if (inputValues === null) { return 0 }

  const nonNullInputValues = inputValues.filter(inputValue => inputValue !== null)

  return round(mean(nonNullInputValues) / max_score * 100)
}

/**
 * Scales an input array values between scaledMin and scaledMax.
 *
 * e.g scaling the range 0-25 to a range between 0-100.
 *
 * scaleBetween([1,3,5,7,8,10,18,22], 0, 100)
 *
 * If your input range doesn't contain the min/max values possible
 * as in the previous example, you can define them:
 *
 * scaleBetween([1,3,5,7,8,10,18,22], 0, 100, 0, 25)
 *
 * Written for issue #167
 * @see https://github.com/achiive/theamazeballscoachingtool/issues/167
 * @param {number[]} arr
 * @param {number} scaledMin
 * @param {number} scaledMax
 * @param {number} min
 * @param {number} max
 * @returns {number[]}
 */
export function scaleBetween (arr = [], scaledMin = 0, scaledMax = 0, min, max) {
  if (!min) {
    min = Math.min.apply(Math, arr)
  }
  if (!max) {
    max = Math.max.apply(Math, arr)
  }
  return arr.map(num => (scaledMax - scaledMin) * (num - min) / (max - min) + scaledMin)
}

/**
 * Identical functionality to scaleBetween but acts on a singular number.
 *
 * Written for issue #167
 * @see https://github.com/achiive/theamazeballscoachingtool/issues/167
 * @param {number} unscaledNum
 * @param {number} minAllowed
 * @param {number} maxAllowed
 * @param {number} min
 * @param {number} max
 * @returns {number}
 */
export function scale (unscaledNum, minAllowed, maxAllowed, min, max) {
  return (maxAllowed - minAllowed) * (unscaledNum - min) / (max - min) + minAllowed
}

/**
 * Takes a unix timestamp from PHP and produces a friendly
 * date.
 *
 * Default format outputs: 12:15 21 Nov
 *
 * @param {number} unix
 * @param {string} f
 */
export function formatDateFromPHPUnixTime (unix, f = 'h:mm a dddd, Do MMMM') {
  return momentFromPHPUnixTime(unix).format(f)
}

export function calendarDateFromPHPUnixTime (unix) {
  return momentFromPHPUnixTime(unix).fromNow()
}

export function humanDateFromPHPUnixTime (unix) {
  const m = momentFromPHPUnixTime(unix)
  const now = moment()
  if (m.isBefore(now.add(6, 'days'))) {
    return m.calendar()
  }
  return m.fromNow()
}

export function getMetaContent (name) {
  name = document.getElementsByTagName('meta')[name]
  if (name !== undefined) {
    name = name.getAttribute('content')
    if (name !== undefined) {
      return name
    }
  }
  return null
}
