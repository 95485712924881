<template>
  <div>
    <div
      class="w-full h-full rounded-lg bg-grey-lighter overflow-hidden border border-grey"
      title="Click to update avatar"
    >
      <label
        slot="upload-label"
        for="fileInput"
        class="cursor-pointer"
      ><img :src="avatar"></label>
    </div>
    <div
      :class="{'hidden': !popupVisible}"
      class="absolute bg-white p-4 border shadow-lg z-50"
    >
      <div :class="{'hidden': fileLoadedError}">
        <vue-croppie
          ref="croppieRef"
          :enable-orientation="true"
          :enable-resize="false"
          :viewport="{ width: 200, height: 200, type: 'circle' }"
          :boundary="{ width: 300, height: 300}"
          :enable-exif="true"
          @update="update"
        />
      </div>

      <div :class="{'hidden': !fileLoaded}">
        <div class="flex">
          <button
            class="p-2 border border-accent rounded"
            @click="closePopup"
          >
            Cancel
          </button>
          <span class="flex-1" />
          <button
            class="p-2 bg-accent text-accent-contrast rounded"
            @click="saveImage"
          >
            {{ message }}
          </button>
        </div>
      </div>

      <div :class="{'hidden': !fileLoadedError}">
        <p class="my-2">
          There was an error trying to load this file.<br>If this problem persists, then please contact your local support team.
        </p>
        <div class="flex">
          <button
            class="p-2 border border-accent rounded"
            @click="closePopup"
          >
            Cancel
          </button>
          <span class="flex-1" />
          <label
            for="fileInput"
            class="p-2 bg-accent text-accent-contrast rounded inline-block"
          >Try again</label>
        </div>
      </div>

      <input
        id="fileInput"
        class="hidden"
        type="file"
        name="fileInput"
        @change="loadFile"
      >
    </div>
  </div>
</template>

<script>
  import MagicApiService from 'services/api/magic'

  export default {
    name: 'EditableUserAvatar',
    props: {
      default: {
        type: String
      },
      uuid: {
        type: String,
        default: 'self'
      }
    },
    data () {
      return {
        encodedImage: '',
        visibleImage: '',
        popupVisible: false,
        fileLoaded: false,
        fileLoadedError: false,
        message: 'Save'
      }
    },
    computed: {
      avatar () {
        if (this.visibleImage) {
          return this.visibleImage
        }
        return (this.default === null) ? null : this.default
      }
    },
    mounted () {
      this.MagicApi = new MagicApiService(this.$store)
    },
    methods: {
      readFile (file) {
        return new Promise((resolve, reject) => {
          const fr = new FileReader()
          fr.onload = () => resolve(fr.result)
          fr.onerror = () => reject()
          fr.readAsDataURL(file) // or readAsText(file) to get raw content
        })
      },

      async loadFile (ev) {
        const self = this
        const files = ev.target.files

        self.fileLoaded = false
        self.fileLoadedError = false
        // Have to make sure the popup is visible before croppie is triggered as it
        // relies on having the element visible, and sized, to work out the initial zoom
        self.popupVisible = true

        const result = await this.readFile(files[0])

        try {
          await self.$refs.croppieRef.bind({
            url: result
          })

          self.fileLoaded = true
          self.fileLoadedError = false
          self.popupVisible = true
          self.message = 'Save'
        } catch (e) {
          self.fileLoaded = false
          self.fileLoadedError = true
          self.popupVisible = true
        }
      },
      update () {
        this.$refs.croppieRef.result({ format: 'jpeg', circle: false }, (output) => {
          this.encodedImage = output
        })
      },
      closePopup () {
        this.popupVisible = false
        this.message = 'Save'
      },
      async saveImage () {
        // Upload the image to the server against this user.
        this.message = 'Saving...'
        const success = await this.MagicApi.updateAvatar(this.encodedImage, this.uuid)

        if (success) {
          this.visibleImage = this.encodedImage
          this.closePopup()
        } else {
          this.fileLoadedError = true
          this.fileLoaded = false
        }
      }

    }
  }

</script>
