import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { getMetaContent } from './helpers'
import { logger } from '../plugins/Logger'

Vue.use(VueI18n)

const dateTimeFormat = {
  short: {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  },
  long: {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    weekday: 'long',
    hour: 'numeric',
    minute: 'numeric'
  }
}

const i18n = new VueI18n({
  dateTimeFormats: {
    en: dateTimeFormat
  },
  locale: 'en',
  messages: {}
})

/**
 * Load messages into locale from json input.
 *
 * @param locale
 * @param messages
 * @returns {Promise<void>}
 */
 export async function setLocaleFromJson (locale) {
  logger.log(`[*] Setting i18n locale to ${locale} from Json`)
  if (i18n.locale !== locale) {
    i18n.fallbackLocale = i18n.locale
    i18n.locale = locale
  }
}

/**
 * Load messages into locale from json input.
 *
 * @param locale
 * @param messages
 * @returns {Promise<void>}
 */
export async function loadMessagesFromJson (locale, messages) {
  logger.log(`[*] Loading i18n from Json into locale [${locale}]`)
  i18n.setLocaleMessage(locale, messages)
}

/**
 * Load date time format into locale from json input.
 *
 * @param locale
 * @param format
 * @returns {Promise<void>}
 */
 export async function loadDateTimeFormatsFromJson (locale, format) {
  logger.log(`[*] Loading i18n date time formats from Json into locale [${locale}]`)
  i18n.setDateTimeFormat(locale, dateTimeFormat)
}

/**
 * Load messages from meta tag; this is done on first load of page so
 * that we have at least the bare-bones translations set.
 *
 * @param name
 * @returns {Promise<void>}
 */
export async function bootstrapMessagesFromMetaTag (name = 'initial-state') {
  let initData = getMetaContent(name)
  if (initData) {
    logger.log(`[*] Loading i18n from meta tag named [${name}]`)
    initData = JSON.parse(initData)
    if (initData.data && initData.data.attributes && initData.data.attributes.translations) {
      await loadMessagesFromJson('en', initData.data.attributes.translations)
    }
    if (initData.translations) {
      await loadMessagesFromJson('en', initData.translations)
    }
  } else {
    logger.error(`[!] No meta tag could be found named [${name}]`)
  }
}

export default i18n
