<template>
  <div
    id="content"
    class="blank-layout flex justify-center items-center"
  >
    <div class=" sm:w-1/2 xl:w-1/3">
      <router-view class="z-0" />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'BlankLayout'
  }
</script>

<style>
    #content.blank-layout{
        width: 100%;
        height: 100%;
        background-color: transparent !important;
    }
</style>
