var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loginstate === 'loading')?_c('section',{staticClass:"login-polling"},[_c('div',{staticClass:"flex justify-center items-center bg-white shadow-md rounded p-8 mb-4"},[_c('h3',{staticClass:"primary"},[_vm._v("\n        "+_vm._s(_vm.$t('frontend.auth.loading'))+"\n      ")])])]):_vm._e(),_vm._v(" "),(_vm.loginstate === 'login')?_c('section',{staticClass:"login-form"},[_c('div',{staticClass:"bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"},[_c('h3',{staticClass:"mb-4"},[_c('fa',{staticClass:"mr-2",attrs:{"icon":"fingerprint"}}),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._s(
            _vm.$t('frontend.auth.heading', {
              company: _vm.$t('frontend.global.common.heading.company_app_name'),
            })
          )}})],1),_vm._v(" "),(_vm.hasStatus)?_c('div',{staticClass:"status-message",class:{ error: ['error', 'unrecoverable'].indexOf(_vm.status) !== -1 }},[_c('p',{staticClass:"px-8"},[_c('i',{staticClass:"fas fa-exclamation-triangle"}),_vm._v(" "+_vm._s(_vm.statusMsg)+"\n        ")])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"mb-4"},[_c('label',{staticClass:"block text-grey-darker text-sm font-bold mb-2",class:{ 'text-red': _vm.hasValidationError('email') },attrs:{"for":"email"}},[_vm._v("\n          "+_vm._s(_vm.$t('frontend.auth.form.email'))+"\n        ")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"mb-2 shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:shadow-outline",attrs:{"id":"email","name":"email","disabled":['sending', 'unrecoverable'].indexOf(_vm.status) !== -1,"type":"text","placeholder":"jack@example.com"},domProps:{"value":(_vm.email)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onSubmit($event)},"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),_vm._v(" "),(_vm.hasValidationError('email'))?_c('p',{staticClass:"text-red text-xs italic"},[_vm._v("\n          "+_vm._s(_vm.firstValidationError('email'))+"\n        ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"flex items-center justify-between"},[_c('span',{staticClass:"flex-1"}),_vm._v(" "),_c('button',{staticClass:"bg-accent hover:bg-accent text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-1/3",class:{
            'opacity-50 cursor-not-allowed':
              ['sending', 'unrecoverable'].indexOf(_vm.status) !== -1,
          },attrs:{"disabled":['sending', 'unrecoverable'].indexOf(_vm.status) !== -1,"type":"button"},on:{"click":_vm.onSubmit}},[_vm._v("\n          "+_vm._s(_vm.$t('frontend.auth.form.sign-in'))+"\n        ")])])])]):_vm._e(),_vm._v(" "),(_vm.loginstate === 'polling')?_c('section',{staticClass:"login-polling"},[_c('div',{staticClass:"flex justify-center items-center bg-white shadow-md rounded px-8 py-8 mb-4"},[_c('div',{staticClass:"icon-container text-4xl mr-5 primary"},[_c('fa',{class:['mr-2', _vm.icon.class],attrs:{"icon":_vm.icon.ico}})],1),_vm._v(" "),_c('div',{staticClass:"flex-1"},[_c('h3',{staticClass:"primary",domProps:{"innerHTML":_vm._s(_vm.title)}}),_vm._v(" "),_c('p',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.message)}}),_vm._v(" "),(_vm.pollingstate === 'is-late')?_c('span',{staticStyle:{"padding-left":"5px"}},[_c('a',{class:[
                _vm.resending
                  ? 'text-grey-light cursor-not-allowed'
                  : 'text-blue hover:text-blue-darker' ],attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.resend($event)}}},[_vm._v("Click here to resend.")])]):_vm._e()])])])]):_vm._e(),_vm._v(" "),(_vm.loginstate === 'routelist')?_c('section',[_c('div',{staticClass:"bg-white shadow-md rounded"},[_c('div',{staticClass:"flex justify-center items-center px-8 pt-8 mb-4"},[_c('EditableUserAvatar',{staticClass:"rounded-lg w-16 h-16",attrs:{"default":_vm.avatar}}),_vm._v(" "),_c('div',{staticClass:"flex-1 ml-4"},[_c('h1',{staticClass:"m-0 text-2xl"},[_vm._v("\n            "+_vm._s(_vm.user.name)+"\n          ")]),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.$t('frontend.auth.sections.message')))])])],1),_vm._v(" "),_c('div',{staticClass:"flex flex-col justify-center items-stretch px-8 pb-8 mb-4"},[_vm._l((_vm.authorisedRoutes),function(route,i){return _c('button',{key:i,staticClass:"text-center m-4 p-4 py-6 rounded shadow-md border border-grey",on:{"click":function($event){$event.preventDefault();return _vm.navigate(route.href)}}},[_vm._v("\n          "+_vm._s(route.name)+"\n        ")])}),_vm._v(" "),_c('a',{staticClass:"self-end m-4",attrs:{"href":"/logout"}},[_vm._v(_vm._s(_vm.$t('frontend.auth.sections.not-you')))])],2)])]):_vm._e(),_vm._v(" "),_c('p',{staticClass:"text-center text-grey-lightest text-xs"},[_vm._v("\n    "+_vm._s(_vm.$t('frontend.global.common.copyright'))+"\n  ")])])}
var staticRenderFns = []

export { render, staticRenderFns }