import { logger as PrettyLogger } from './PrettyLogging'

/**
 * This logger class provides a console-style interface which will notify both the conosle (via PrettyLogger), and atatus
 * with any errors and logging information
 *
 * This class is either called directly by importing `logger` and then using `logger.error(e)`, or alternatively can
 * use the `$logger` property on the Vue prototype as `this.$logger.error(e)`
 */
const logger = (() => {
  const api = {}
  api.debug = (...args) => {
    if (process.env.NODE_ENV !== 'production') PrettyLogger.debug(...args)
  }
  api.log = (...args) => {
    if (process.env.NODE_ENV !== 'production') PrettyLogger.log(...args)
  }
  api.warn = (...args) => {
    if (process.env.NODE_ENV !== 'production') PrettyLogger.warn(...args)
  }
  api.groupCollapsed = (...args) => {
    if (process.env.NODE_ENV !== 'production')
      PrettyLogger.groupCollapsed(...args)
  }
  api.groupEnd = (...args) => {
    if (process.env.NODE_ENV !== 'production') PrettyLogger.groupEnd(...args)
  }

  /** This is used for when the Error is returned from Axios, and will automatically inject the returned value from the server */
  api.responseerror = (e, ...args) => {
    if (e.response && e.response.data) {
      logger.error(e, e.response.data, ...args)
    } else {
      logger.error(e, ...args)
    }
  }

  api.error = (e, ...args) => {
    PrettyLogger.error(e, ...args)
  }

  return api
})()

const LoggerPlugin = {
  install: function(Vue) {
    Vue.prototype.$logger = logger
  },
}

export { logger, LoggerPlugin }
