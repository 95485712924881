import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import { default as i18n, bootstrapMessagesFromMetaTag } from 'services/i18n'
import Guest from 'plugins/Guest'
import Atatus from 'plugins/Atatus'
import Version from 'plugins/Version'

import FontAwesome from 'plugins/FontAwesome'
import '../../sass/magic-auth.scss'

import DefaultLayout from 'components/layouts/blank'

import VueLoggerErrorHandler from 'plugins/VueLoggerErrorHandler'
import { LoggerPlugin } from 'plugins/Logger'

import VueCroppie from 'vue-croppie'
import 'croppie/croppie.css'

Vue.use(LoggerPlugin)
Vue.use(VueLoggerErrorHandler) // import the croppie css manually
Vue.use(VueCroppie)

Vue.config.productionTip = false

Vue.use(Version)
Vue.use(Guest)
Vue.use(FontAwesome)

Vue.component('DefaultLayout', DefaultLayout)

/* eslint-disable no-new */
new Vue({
  i18n,
  router,
  store,
  async beforeCreate() {
    console.log('[*] Bootstrapping beforeCreate')
    await bootstrapMessagesFromMetaTag()
  },
  ...App,
})
