// We don't want to persist this by default
export const persist = false

const getDefaultState = () => {
  return {
    emailEndpoint: '',
    pollingEndpoint: '',
    authorisedRoutes: {}
  }
}

// Initial State
export const state = getDefaultState()

export const types = {
  SET_MAGIC_ENDPOINTS: 'SET_MAGIC_ENDPOINTS',
  SET_MAGIC_AUTHORISED_ROUTES: 'SET_MAGIC_AUTHORISED_ROUTES'
}

export const mutations = {
  [types.SET_MAGIC_ENDPOINTS] (state, { email_endpoint, polling_endpoint }) {
    state.emailEndpoint = email_endpoint || ''
    state.pollingEndpoint = polling_endpoint || ''
  },
  [types.SET_MAGIC_AUTHORISED_ROUTES] (state, data) {
    state.authorisedRoutes = data || {}
  }
}

export const actions = {
  /**
   * Set the magic auth endpoints from server response.
   *
   * @param commit
   * @param payload
   */
  setMagicAuthEndpoints ({ commit }, payload) {
    commit(types.SET_MAGIC_ENDPOINTS, payload.routes)
    return true
  },

  /**
   * Set the authorised routes from server response.
   *
   * @param commit
   * @param payload
   */
  setAuthorisedRoutes ({ commit }, payload) {
    commit(types.SET_MAGIC_AUTHORISED_ROUTES, payload)
    return true
  }
}

export const getters = {
  hasMagicEndpoints: state => {
    return state.emailEndpoint.length > 0 && state.pollingEndpoint.length > 0
  },
  getMagicEndpoints: state => {
    return { email: state.emailEndpoint, polling: state.pollingEndpoint }
  },
  hasAuthorisedRoutes: state => {
    return Object.keys(state.authorisedRoutes).length > 0
  },
  getAuthorisedRoutes: state => {
    return state.authorisedRoutes
  },
  checkRoute: (state) => {
    return (route) => {
      return route in state.authorisedRoutes
    }
  }
}
