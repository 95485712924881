import { logger } from './Logger'

export default {
  install: function (Vue) {
    logger.log(`[*] Installing Version [${APP_ENV}-${APP_GIT_DESCRIBE}]`)

    Vue.prototype.$version = {
      text: `${APP_ENV}-${APP_GIT_DESCRIBE}`,
      env: APP_ENV,
      version: APP_GIT_DESCRIBE,
      hash: APP_GIT_HASH,
      dirty: APP_GIT_DIRTY,
      commits: APP_NUM_COMMITS,
      built: APP_BUILD_ON
    }
  }
}
