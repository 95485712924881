import Axios from 'axios'
import Request from 'axios-request-handler'

/**
 * This was copied from the PWA auth service for #112. Eventually
 * the magic auth app and the PWA will share this file for
 * managing authentication.
 * @todo see above
 */
export default function(Store) {
  return {
    /**
     * Accepts an object containing credentials to post to the API
     * makeLoginAttempt route.
     *
     * @param credentials
     * @returns {Promise<AxiosResponse<any>>}
     */
    async makeLoginAttempt(credentials) {
      const response = await Axios.post('/api/v1/a/login', credentials)

      const data = response.data

      if (data.success === false) {
        throw {
          status: 'error',
          msg: data.data.message
            ? data.data.message
            : 'Unexpected error, please refresh and try again',
          errors: data.data.errors ? data.data.errors : undefined,
          response: response,
        }
      }

      return data.data
    },

    /**
     * Makes a request to the backend for a fresh magic link to be sent.
     *
     * @returns {AxiosPromise<any>}
     */
    async resendMagicLink(emailEndpoint) {
      return await Axios.get(emailEndpoint)
    },

    /**
     * Polls the backend for a login token.
     *
     * @param pollingEndpoint
     * @param completeFn
     * @param timeoutFn
     * @param interval
     * @param timeout
     * @returns {CancelToken}
     */
    pollForMagicLogin(
      pollingEndpoint,
      completeFn = () => false,
      timeoutFn = () => true,
      interval = 1000,
      timeout = 20000
    ) {
      const pollingRequest = new Request(pollingEndpoint)
      let endTime = Number(new Date()) + timeout

      pollingRequest.poll(interval).get(response => {
        if (typeof response === 'undefined') {
          return false
        }

        if (response.status === 200) {
          return completeFn(this.updateStore(response.data))
        }

        // Extend the timeout or cancel polling
        if (Number(new Date()) > endTime) {
          if (!timeoutFn(response)) {
            return false
          }
          endTime = Number(new Date()) + timeout
        }
      })
      return pollingRequest
    },

    // Updates the VueX store with the given response data. This either comes from the Magic Login poll, or WhoAmI
    updateStore(responsedata) {
      if (responsedata.data) {
        if (Store) {
          Store.dispatch('auth/setUser', responsedata.data)
          Store.dispatch(
            'magic/setAuthorisedRoutes',
            responsedata.authorised_routes
          )
        }
        return responsedata
      } else {
        if (Store) {
          Store.dispatch('auth/resetState')
          Store.dispatch('magic/setAuthorisedRoutes', {})
        }
        return false
      }
    },

    // Returns the user/route object, or false based on the current login
    async whoAmI() {
      const response = await Axios.get('/api/v2/user/me').catch(function() {
        return false
      })
      if (response.data) {
        return this.updateStore(response.data)
      } else {
        return false
      }
    },

    // Returns the user/route object, or false based on the current login
    async updateAvatar(avatar, uuid) {
      const response = await Axios.post('/api/v1/a/avatar', { avatar, uuid })
      return response.data.success
    },
  }
}
