import * as atatus from 'atatus-js'
import { logger } from './Logger'

const Atatus = {
  install: function (tags) {
    const c = {
      tags: tags,
      offline: true,
      version: APP_GIT_DESCRIBE,
      debugMode: true
    }

    c.tags.push(APP_ENV)
    logger.log(`[*] Installing Atatus tagged as: [${c.tags.join(', ')}]`)

    if (!process.env.ATATUS_API_KEY) {
      logger.error('The apiKey option is required to install Atatus')
      return
    }

    atatus.config(process.env.ATATUS_API_KEY, c).install()
  }
}

export default Atatus
