import { library, dom } from '@fortawesome/fontawesome-svg-core'
import {
  faExclamationCircle as faSolidExclamationCircle,
  faBullseye,
  faPlusCircle as faSolidPlusCircle,
  faMinusCircle as faSolidMinusCircle,
  faPlusSquare as faSolidPlusSquare,
  faMinusSquare as faSolidMinusSquare,
  faLock,
  faCircle as faSolidCircle,
  faStarHalfAlt,
  faClipboardList,
  faArrowDown,
  faPlus,
  faMinus,
  faExclamationTriangle as faSolidExclamationTriangle,
  faExclamation as faSolidExclamation,
  faStopwatch,
  faCircleNotch,
  faBars,
  faAngleRight,
  faQuestion,
  faSearch as faSolidSearch,
  faFingerprint,
  faTimes,
  faCheck,
  faDotCircle as faSolidDotCircle,
  faCaretRight as faSolidCaretRight,
  faCaretDown as faSolidCaretDown,
  faCaretUp as faSolidCaretUp,
  faSquare as faSolidSquare
} from '@fortawesome/pro-solid-svg-icons'

import {
  faSync,
  faBell,
  faWifiSlash,
  faPlayCircle,
  faStopCircle,
  faCheckCircle as faLightCheckCircle,
  faCircle as faLightCircle,
  faArrowCircleRight,
  faArrowCircleLeft,
  faTimesCircle,
  faSortAmountUp,
  faSortAmountDownAlt,
  faGlobeAmericas,
  faFolder,
  faFolders,
  faUsers as faLightUsers,
  faUserCog,
  faChartLine as faLightChartLine,
  faChartBar as faLightChartBar,
  faUsersCog,
  faUserChart as faLightUserChart,
  faChalkboardTeacher,
  faUser as faLightUser,
  faUserPlus,
  faPlusCircle,
  faMinusCircle,
  faFolderPlus,
  faFolderMinus,
  faUserMinus,
  faUserLock,
  faEye,
  faEdit as faLightEdit,
  faUserEdit,
  faUserSlash,
  faEnvelope,
  faEnvelopeOpen,
  faExclamationTriangle,
  faSitemap,
  faQuestionCircle,
  faExclamationCircle,
  faMobile,
  faTabletAndroid,
  faLaptop,
  faDotCircle as faLightDotCircle,
  faTimes as faLightTimes,
  faTh,
  faFileInvoice,
  faCalendarAlt,
  faSearch as faLightSearch,
  faSignOutAlt,
  faAnalytics,
  faChartNetwork,
  faComments as faLightComments,
  faBullseye as faLightBullseye,
  faPlus as faLightPlus,
  faListAlt as faLightListAlt,
  faCodeBranch,
  faAbacus,
  faArrowRight,
  faStar as faLightStar,
  faCaretRight,
  faCaretDown,
  faStars,
  faSquare as faLightSquare
} from '@fortawesome/pro-light-svg-icons'

import {
  faSmile,
  faFrown,
  faMeh,
  faComments,
  faStar,
  faCircle,
  faChartBar,
  faChartLine,
  faCheckCircle,
  faClipboardListCheck,
  faEdit,
  faExternalLink,
  faListAlt,
  faDotCircle,
  faSearch,
  faSquare,
  faPlusSquare,
  faMinusSquare,
  faUser,
  faUserChart,
  faUsers
} from '@fortawesome/pro-regular-svg-icons'

import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'
import { logger } from './Logger'

export default {
  install: function (Vue) {
    logger.log('[*] Installing FontAwesome')
    Vue.component('Fa', FontAwesomeIcon)
    Vue.component('FaLayers', FontAwesomeLayers)

    // Solid icons
    library.add(
      faSolidExclamationCircle,
      faBullseye,
      faSolidPlusCircle,
      faSolidMinusCircle,
      faSolidPlusSquare,
      faSolidMinusSquare,
      faLock,
      faSolidCircle,
      faStarHalfAlt,
      faClipboardList,
      faArrowDown,
      faPlus,
      faMinus,
      faUsers,
      faSolidExclamationTriangle,
      faSolidExclamation,
      faStopwatch,
      faCircleNotch,
      faBars,
      faAngleRight,
      faQuestion,
      faSolidSearch,
      faTh,
      faChartBar,
      faFileInvoice,
      faUserCog,
      faFingerprint,
      faTimes,
      faCheck,
      faSolidDotCircle,
      faSolidCaretRight,
      faSolidCaretDown,
      faSolidCaretUp,
      faSolidSquare
    )

    // Light weight icons
    library.add(
      faStars,
      faSync,
      faBell,
      faWifiSlash,
      faPlayCircle,
      faStopCircle,
      faLightCheckCircle,
      faLightCircle,
      faArrowCircleRight,
      faArrowCircleLeft,
      faTimesCircle,
      faSortAmountUp,
      faSortAmountDownAlt,
      faGlobeAmericas,
      faFolder,
      faFolders,
      faLightUsers,
      faUserCog,
      faLightChartBar,
      faLightChartLine,
      faUsersCog,
      faLightUserChart,
      faChalkboardTeacher,
      faLightUser,
      faUserPlus,
      faPlusCircle,
      faMinusCircle,
      faFolderPlus,
      faFolderMinus,
      faUserMinus,
      faUserLock,
      faEye,
      faLightEdit,
      faUserEdit,
      faUserSlash,
      faEnvelope,
      faEnvelopeOpen,
      faExclamationTriangle,
      faSitemap,
      faQuestionCircle,
      faExclamationCircle,
      faMobile,
      faTabletAndroid,
      faLaptop,
      faLightDotCircle,
      faLightTimes,
      faLightSearch,
      faSignOutAlt,
      faAnalytics,
      faChartNetwork,
      faLightComments,
      faLightBullseye,
      faLightPlus,
      faLightListAlt,
      faCodeBranch,
      faAbacus,
      faArrowRight,
      faLightStar,
      faCaretRight,
      faCaretDown,
      faLightSquare
    )

    // Regular icons
    library.add(
      faSmile,
      faFrown,
      faMeh,
      faComments,
      faStar,
      faCircle,
      faChartLine,
      faCheckCircle,
      faClipboardListCheck,
      faEdit,
      faExternalLink,
      faListAlt,
      faCalendarAlt,
      faDotCircle,
      faPlusSquare,
      faMinusSquare,
      faSearch,
      faSquare,
      faUser,
      faUserChart,
      faUsers
    )

    dom.watch()
  }
}
