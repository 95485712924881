import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import localforage from 'localforage'

Vue.use(Vuex)

// Load modules dynamically
const requireContext = require.context('./modules', false, /.*\.js$/)

const modules = requireContext.keys()
  .map(file =>
    [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)]
  )
  .reduce((modules, [name, module]) => {
    if (module.namespaced === undefined) {
      module.namespaced = true
    }

    return { ...modules, [name]: module }
  }, {})

modules.magic = require('store/modules/magic')
modules.magic.namespaced = true

modules.auth = require('store/modules/auth')
modules.auth.namespaced = true

const vuexLocal = new VuexPersistence({
  storage: localforage,
  key: 'achiive_magic',
  modules: ['auth', 'magic']
})

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules,
  mutations: {
    RESTORE_MUTATION: vuexLocal.RESTORE_MUTATION // this mutation **MUST** be named "RESTORE_MUTATION" https://www.npmjs.com/package/vuex-persist#support-strict-mode
  },
  plugins: [vuexLocal.plugin]
})
