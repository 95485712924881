export const state = {
    locale: 'en'
}

export const types = {
    // ...
}

export const mutations = {
    // ...
}

export const actions = {
    // ...
}

export const getters = {
    locale: state => state.locale
}
