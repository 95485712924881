<template>
  <LoginForm />
</template>

<script>
  import LoginForm from 'components/partials/LoginForm'

  export default {
    name: 'Login',
    components: {
      LoginForm
    }
  }
</script>
