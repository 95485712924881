<template>
  <div id="app">
    <component
      :is="layout"
      v-if="!locked"
    />
  </div>
</template>

<script>
  export default {
    el: '#app',
    name: 'App',
    data () {
      return { locked: true }
    },
    computed: {
      layout () {
        return (this.$route.meta.layout || 'default') + '-layout'
      }
    },
    created () {
      // Check we aren't already logged in
      this.locked = false
    }
  }
</script>
