import Vue from 'vue'
import Router from 'vue-router'
import Login from '../components/views/Login'

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'login',
      component: Login
    }
  ]
})

// @todo 404 route
// @todo error on landing on routing if not authenticated
// @todo error on landing on polling if not got polling data

export default router
