/*
 * NOTE: You will find that your console attributes log lines pushed
 * through this pipe to PrettyLogging.js To remove that you need to
 * tell Chrome DevTools to blackbox PrettyLogging.js.
 *
 * For information on how to do that see:
 * https://developers.google.com/web/tools/chrome-devtools/javascript/guides/blackbox-chrome-extension-scripts
 */
const logger = (() => {
  let inGroup = false

  const methodToColorMap = {
    debug: '#7f8c8d', // Gray
    log: '#2ecc71', // Green
    warn: '#f39c12', // Yellow
    error: '#c0392b', // Red
    groupCollapsed: '#7748A8', // Achiive Purple
    groupEnd: null // No colored prefix on groupEnd
  }

  const print = function (method, args) {
    if (method === 'groupCollapsed') {
      // Safari doesn't print all console.groupCollapsed() arguments:
      // https://bugs.webkit.org/show_bug.cgi?id=182754
      if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
        console[method](...args)
        return
      }
    }

    const styles = [
      `background: ${methodToColorMap[method]}`,
      'border-radius: 0.5em',
      'color: white',
      'font-weight: bold',
      'padding: 2px 0.5em'
    ]

    // When in a group, the workbox prefix is not displayed.
    const logPrefix = inGroup ? [] : ['%ccoaching-tool', styles.join(';')]

    console[method](...logPrefix, ...args)

    if (method === 'groupCollapsed') {
      inGroup = true
    }
    if (method === 'groupEnd') {
      inGroup = false
    }
  }

  const api = {}
  for (const method of Object.keys(methodToColorMap)) {
    api[method] = (...args) => {
      if (process.env.NODE_ENV !== 'production') {
        print(method, args)
      }
    }
  }

  return api
})()

export { logger }
