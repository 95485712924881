import { logger } from './Logger'

/**
 * This class replaces the built in Vue Error Handler with one which interacts with our Logger class.
 *
 * In theory it could be part of the Logger plugin directly, but at the time of writing, the Logger class wasn't
 * Vue aware, and this provided the link between Vue and the Logger
 */

/*
 * classifyRE, classify and formatComponentName are taken from
 * node_modules/vue-template-compiler/build.js due to them
 * seemingly being made private methods in Vue2.
 */
const classifyRE = /(?:^|[-_])(\w)/g
const classify = function (str) {
  return str
    .replace(classifyRE, function (c) {
      return c.toUpperCase()
    })
    .replace(/[-_]/g, '')
}
const formatComponentName = function (vm, includeFile) {
  if (vm.$root === vm) {
    return '<Root>'
  }
  const options = typeof vm === 'function' && vm.cid != null
    ? vm.options
    : vm._isVue
      ? vm.$options || vm.constructor.options
      : vm
  let name = options.name || options._componentTag
  const file = options.__file
  if (!name && file) {
    const match = file.match(/([^/\\]+)\.vue$/)
    name = match && match[1]
  }

  return (
    (name ? ('<' + (classify(name)) + '>') : '<Anonymous>') +
    (file && includeFile !== false ? (' at ' + file) : '')
  )
}

const VueLoggerErrorHandler = {
  /**
   *
   * @param {Object} Vue
   */
  install: function (Vue) {
    logger.log('[*] Replaced Vue ErrorHandler with Logger')
    const _oldOnError = Vue.config.errorHandler
    Vue.config.errorHandler = function VueErrorHandler (error, vm) {
      logger.error(error, {
        extra: {
          componentName: formatComponentName(vm),
          propsData: vm.$options.propsData
        }
      })
      if (typeof _oldOnError === 'function') {
        _oldOnError.call(this, error, vm)
      }
    }
  }
}

export default VueLoggerErrorHandler
