<template>
  <div>
    <section v-if="loginstate === 'loading'" class="login-polling">
      <div
        class="flex justify-center items-center bg-white shadow-md rounded p-8 mb-4"
      >
        <h3 class="primary">
          {{ $t('frontend.auth.loading') }}
        </h3>
      </div>
    </section>

    <section v-if="loginstate === 'login'" class="login-form">
      <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <h3 class="mb-4">
          <fa icon="fingerprint" class="mr-2" />
          <span
            v-html="
              $t('frontend.auth.heading', {
                company: $t('frontend.global.common.heading.company_app_name'),
              })
            "
          />
        </h3>

        <div
          v-if="hasStatus"
          :class="{ error: ['error', 'unrecoverable'].indexOf(status) !== -1 }"
          class="status-message"
        >
          <p class="px-8">
            <i class="fas fa-exclamation-triangle" /> {{ statusMsg }}
          </p>
        </div>

        <div class="mb-4">
          <label
            :class="{ 'text-red': hasValidationError('email') }"
            class="block text-grey-darker text-sm font-bold mb-2"
            for="email"
          >
            {{ $t('frontend.auth.form.email') }}
          </label>
          <input
            id="email"
            v-model="email"
            name="email"
            :disabled="['sending', 'unrecoverable'].indexOf(status) !== -1"
            class="mb-2 shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="jack@example.com"
            @keyup.enter="onSubmit"
          />
          <p v-if="hasValidationError('email')" class="text-red text-xs italic">
            {{ firstValidationError('email') }}
          </p>
        </div>
        <div class="flex items-center justify-between">
          <span class="flex-1" />
          <button
            :class="{
              'opacity-50 cursor-not-allowed':
                ['sending', 'unrecoverable'].indexOf(status) !== -1,
            }"
            :disabled="['sending', 'unrecoverable'].indexOf(status) !== -1"
            class="bg-accent hover:bg-accent text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-1/3"
            type="button"
            @click="onSubmit"
          >
            {{ $t('frontend.auth.form.sign-in') }}
          </button>
        </div>
      </div>
    </section>

    <section v-if="loginstate === 'polling'" class="login-polling">
      <div
        class="flex justify-center items-center bg-white shadow-md rounded px-8 py-8 mb-4"
      >
        <div class="icon-container text-4xl mr-5 primary">
          <fa :icon="icon.ico" :class="['mr-2', icon.class]" />
        </div>
        <div class="flex-1">
          <h3 class="primary" v-html="title" />
          <p>
            <span v-html="message" />
            <span v-if="pollingstate === 'is-late'" style="padding-left: 5px;">
              <a
                href="#"
                :class="[
                  resending
                    ? 'text-grey-light cursor-not-allowed'
                    : 'text-blue hover:text-blue-darker',
                ]"
                @click.prevent="resend"
                >Click here to resend.</a
              >
            </span>
          </p>
        </div>
      </div>
    </section>

    <section v-if="loginstate === 'routelist'">
      <div class="bg-white shadow-md rounded">
        <div class="flex justify-center items-center px-8 pt-8 mb-4">
          <EditableUserAvatar class="rounded-lg w-16 h-16" :default="avatar" />

          <div class="flex-1 ml-4">
            <h1 class="m-0 text-2xl">
              {{ user.name }}
            </h1>
            <p>{{ $t('frontend.auth.sections.message') }}</p>
          </div>
        </div>
        <div class="flex flex-col justify-center items-stretch px-8 pb-8 mb-4">
          <button
            v-for="(route, i) in authorisedRoutes"
            :key="i"
            class="text-center m-4 p-4 py-6 rounded shadow-md border border-grey"
            @click.prevent="navigate(route.href)"
          >
            {{ route.name }}
          </button>
          <a class="self-end m-4" href="/logout">{{
            $t('frontend.auth.sections.not-you')
          }}</a>
        </div>
      </div>
    </section>
    <p class="text-center text-grey-lightest text-xs">
      {{ $t('frontend.global.common.copyright') }}
    </p>
  </div>
</template>
<script>
import MagicApiService from 'services/api/magic'
import { mapGetters } from 'vuex'
import EditableUserAvatar from '../EditableUserAvatar'

let pollingRequest = null

export default {
  name: 'LoginForm',
  components: { EditableUserAvatar },
  data: function() {
    return {
      status: 'ready',
      statusMsg: '',
      errors: {},
      email: '',
      messages: {
        polling: {
          icon: {
            ico: 'circle-notch',
            class: 'fa-spin',
          },
          title: 'Waiting for authentication&hellip;',
          message:
            'A message has been sent to your email with a login confirmation link. Please go to your email, click the link provided, and return to this page. Once confirmed this page will load the application automatically.',
        },
        'is-late': {
          icon: {
            ico: 'stopwatch',
            class: '',
          },
          title: 'Authentication seems to be taking a while&hellip;',
          message:
            'You should have received an email by now, please check your SPAM folder.',
        },
        error: {
          icon: {
            ico: 'exclamation-triangle',
            class: '',
          },
          title: 'Sorry, the server has returned an error',
          message: 'Please refresh this page and try again.',
        },
        complete: {
          icon: {
            ico: ['far', 'check-circle'],
            class: '',
          },
          title: 'Authentication successful, please wait loading data&hellip;',
          message: '',
        },
      },
      resending: false,
      pollingstate: '', // This is the state of the current polling (polling, is-late, error, or complete)
      loginstate: 'loading', // This determines which section is shown (loading, login page, polling, or routes)
      loginendpoints: {},
    }
  },
  watch: {
    email() {
      this.status = 'ready'
      this.statusMsg = ''
    },
  },
  beforeCreate() {
    this.MagicApi = new MagicApiService(this.$store)
  },
  async destroyed() {
    if (pollingRequest) {
      await pollingRequest.cancel()
    }
  },
  mounted() {
    // Check to see if I'm a user, and jump to the routes step if so.
    this.whoAmI()
  },
  computed: {
    ...mapGetters({
      authorisedRoutes: 'magic/getAuthorisedRoutes',
      user: 'auth/user',
      avatar: 'auth/avatar',
      authCheck: 'auth/check',
    }),
    hasStatus: function() {
      if (typeof this.statusMsg === 'undefined') return false
      return (
        this.statusMsg.length > 0 &&
        ['ready', 'sending'].indexOf(this.status) === -1
      )
    },

    icon: function() {
      return this.messages[this.pollingstate].icon
    },
    title: function() {
      return this.messages[this.pollingstate].title
    },
    message: function() {
      return this.messages[this.pollingstate].message
    },
  },
  methods: {
    // Triggered on the intiial load of this component to double check to see if we're already logged in
    // and whether we should show the existing route list.
    async whoAmI() {
      try {
        // const response = await this.MagicApi.whoAmI()
        if (this.auth.authenticated) {
          this.loginstate = 'routelist'
          return true
        } else {
          this.loginstate = 'login'
          return false
        }
      } catch (e) {
        this.loginstate = 'login'
        return false
      }
    },

    hasValidationError: function(name) {
      if (typeof this.errors === 'undefined') {
        return false
      }
      return name in this.errors
    },
    firstValidationError: function(name) {
      return this.errors[name][0]
    },

    // Submit email
    onSubmit: async function(e) {
      e.preventDefault()
      if (this.status === 'sending') {
        return
      }

      this.status = 'sending'

      try {
        this.loginendpoints = await this.MagicApi.makeLoginAttempt({
          email: this.email,
        })
        this.startPolling()
      } catch (err) {
        if (!err.response) {
          console.error(err)
          this.status = 'unrecoverable'
          return
        }

        if (err.status && err.msg && err.errors) {
          this.status = err.status
          this.msg = err.msg
          this.errors = err.errors
          return
        } else {
          this.statusMsg = 'Unexpected error, please refresh and try again'
          this.errors = undefined
        }

        if (typeof err.response.data !== 'string') {
          this.status = 'error'
          this.statusMsg = err.response.data.message || this.statusMsg
          this.errors = err.response.data.errors || this.errors
        } else {
          this.status = 'unrecoverable' // HTML (string) based response are bad news
        }
      }
    },

    // Then we jump into polling....
    startPolling: function() {
      this.loginstate = 'polling'
      this.pollingstate = 'polling'

      pollingRequest = this.MagicApi.pollForMagicLogin(
        this.loginendpoints.routes.polling_endpoint,
        this.pollingRequestComplete,
        this.pollingRequestTimeout
      )
    },

    // When the polling has successfully completed, we need to log this, and trigger any other events from this.
    async pollingRequestComplete(response) {
      console.log('[*] Polling complete')
      await pollingRequest.cancel()

      if (response) {
        this.pollingstate = 'complete'
        this.loginstate = 'routelist'
        this.$emit('pollingComplete')
      } else {
        this.loginstate = 'login'
      }

      return false // this tells the polling wrapper to stop the timeout
    },

    pollingRequestTimeout() {
      if (this.pollingstate === 'complete') {
        return false
      } // we are already complete, cancel all future requests!
      console.log('[!] Polling Timeout')
      this.pollingstate = 'is-late'
      return true // this tells the polling wrapper to continue polling
    },

    resend() {
      const self = this
      if (this.resending === true) {
        return
      } else {
        this.resending = true
      }
      this.MagicApi.resendMagicLink(
        this.loginendpoints.routes.email_endpoint
      ).then(() => {
        console.log('[*] Magic Email Resent')
        self.pollingstate = 'polling'
        self.resending = false
      })
    },

    navigate(to) {
      window.location.href = to
    },
  },
}
</script>
